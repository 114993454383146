import { IProductInventoryInformation } from '@msdyn365-commerce-modules/retail-actions';

export const getPickupAvailability = (
    productId: number,
    availabilities: IProductInventoryInformation[],
    storeId: string | undefined
): number => {
    let qty = 0;
    if (!storeId) {
        return qty;
    }

    const storeAvailability = availabilities.filter(
        item => item.ProductAvailableQuantity.ProductId === productId && item.InventLocationId === storeId
    );

    if (storeAvailability.length && storeAvailability[0].IsProductAvailable) {
        qty = storeAvailability[0].ProductAvailableQuantity.AvailableQuantity || 0;
    }

    return qty;
};

export const getProductAvailability = (productId: number, availabilities: IProductInventoryInformation[]): number => {
    return (
        availabilities
            .filter(item => item.ProductAvailableQuantity.ProductId === productId)
            .map(item => item.ProductAvailableQuantity.AvailableQuantity)
            .reduce((inc, acc) => (inc || 0) + (acc || 0), 0) || 0
    );
};
